<template lang="pug">
  .lang
    v-layout
      .lang__opacity(v-if="openLengPopup"  @click="openLang(false)")
      v-menu(v-model="showLangMenu", left, bottom, offset-y, nudge-bottom="20", max-height="70%")
        template(v-slot:activator="{ on }")
          v-layout.lang__select.d-flex.align-center.justify-center(v-on="on" @click="openLang(true)")
            .flag(:class="currentLang.val")
        div(@click="openLang(false)")
          v-list.lang__list
            .lang__close-btn(@click="openLang(false)")
              SvgClose
            v-subheader.lang__title {{ $t('actions.chooseLanguage') }}
            v-list-item.lang__item(v-for="lang in langs", :key="lang.id", @click="changeLanguage(lang)")
              .flag(:class="lang.val")
              .lang__text {{ lang.name }}
</template>

<script>
import { SET_WS_UNREAD_NOTIFICATIONS } from '@/store/modules/user/types';
import getAvatarUrlWithResize from '@/utils';
import CONSTANTS from '@/constants';
import SvgClose from '@/components/svg/SvgClose';
import { loadLanguageAsync } from '@/dictionary';

export default {
  components: { SvgClose },
  data() {
    return {
      avatarUrl: '',
      firstName: '',
      lastName: '',
      openLengPopup: false,
      getAvatarUrlWithResize,
      scrolled: false,
      small: false,
      dark: true,
      showLangMenu: false,
      langs: CONSTANTS.LANGUAGES
    };
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 0;
    },
    openLang(item) {
      this.openLengPopup = item;
    },
    changeLanguage({ val, id }) {
      this.openLang();
      if (this.$i18n.locale === val) return;

      localStorage.setItem('language', val);
      loadLanguageAsync(val);

      if (this.user.loggedIn) {
        const payload = {
          data: {
            type: 'user-profile-internal',
            attributes: {
              language_id: id
            }
          }
        };

        this.$store.dispatch('user/updateProfile', payload)
          .then(() => {
            this.$store.dispatch('user/getUser');
          })
          .catch(errors => console.error(errors));
      }
    }
  },
  mounted() {
    loadLanguageAsync(this.$i18n.locale);
    this.$eventHub.$on('WShasUnreadNotifications', notifications => {
      this.$store.commit(`user/${SET_WS_UNREAD_NOTIFICATIONS}`, notifications);
    });

    this.avatarUrl = this.user.avatar_url;
    this.lastName = this.user.last_name;
    this.firstName = this.user.first_name;
  },
  computed: {
    user() { return this.$store.state.user; },
    currentLang() {
      return this.langs.find(lang => lang.val === this.$i18n.locale);
    }
  },
  watch: {
    user: {
      handler(val) {
        if (val.loggedIn) {
          this.avatarUrl = val.avatar_url;
          this.lastName = val.last_name;
          this.firstName = val.first_name;
        }
      },
      deep: true
    },
    scrolled(value) {
      this.small = value > 0;
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';
  .lang{
    border:1px solid rgba(31, 33, 38, 0.1);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    &__select{
      font-size: 14px !important;
      font-weight: 600;
      color: $black;
      cursor: pointer;
    }
    &__title{
      color:$sub-black !important;
      font-weight: 500;
      font-size: 20px;
      @media screen and (max-width: 580px) {
        margin-bottom: 0;
      }
    }
    &__list{
      padding: 30px;
      @media screen and (max-width: 580px) {
          width: 100%;
          height: 100%;
          max-width: 100%;
          padding: 15px 0 15px 30px;
        }
    }
    &__text{
      margin-left: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: #1F2126;
    }
    &__item{
      padding: 0;
      margin-bottom: 10px;
      @media screen and (max-width: 580px) {
        max-width: inherit!important;
        margin-bottom: 0!important;
        padding: 13px 0;
      }
    }

    &__close-btn{
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      @media screen and (max-width: 580px) {
        right: 20px;
        top: 20px;
        cursor: pointer;
      }
    }
    &__opacity{
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 7;
      background: rgba(31, 33, 38, 0.8);
      @media screen and (max-width: 580px) {
        display: none;
      }
    }
  }
    .v-menu{
      &__content{
        position: absolute  !important;
        top: 50%  !important;
        left: 50%  !important;
        transform: translate(-50%, -50%)  !important;
        border-radius: 0;
        @media screen and (max-width: 580px) {
          max-height: 100%!important;
          width: 100%;
          height: 100%;
          min-width: 100%!important;
        }
      }
    }

  .v-subheader{
    padding: 0;
    height: 25px;
    margin-bottom: 25px!important;
  }

  @media screen and (max-width: 600px) {
    .descriptor { font-size: 15px !important; };
  }
</style>
