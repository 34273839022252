<template lang="pug">
  #countries
    v-autocomplete(
      :value="country",
      :items="countries",
      item-text="name",
      item-value="id",
      :no-data-text="$t('validation.noDataAvailable')",
      :label="$t('hints.countrySelect')",
      :rules="[rules.required]",
      :offset-overflow="false",
      attach="#countries",
      autocomplete="off",
      :hide-no-data="true",
      @change="changeCountries($event)",
      :menu-props="{ allowOverflow: false, offsetOverflow: false, bottom: true, offsetY: true }")
      template(v-slot:item="{ item }")
        img(:src="getImgUrl(item.code)")
        | {{ item.name }}
    v-layout.check_ip(align-center, v-if="flagCode")
      img(:src="getImgUrl(flagCode)")
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    country: { type: String, default: '' },
    autorun: { type: Boolean, default: false }
  },
  data() {
    return {
      loading: false,
      flagCode: ''
    };
  },
  computed: {
    ...mapState('user', [
      'countries'
    ]),
    rules() {
      return {
        required: v => !!v || this.$t('validation.required')
      };
    }
  },
  methods: {
    changeCountries(e) {
      this.flagCode = e ? this.countries.filter(item => item.id === e)[0].code : '';
      this.$emit('updateCountry', e);
    },
    getImgUrl(item) {
      let flag = '';
      try {
        flag = require(`../assets/images/flags/country_${this.CountryByCode(item)}.svg`);
      } catch {
        console.warn(`Flag ${item} is not defined`);
      }
      return flag;
    },
    /**
     * @param item
     * @return {string}
     */
    CountryByCode(item) {
      switch (item) {
        case 'ad': return 'adu';
        case 'hm': return 'au';
        case 'sh': return 'gb';
        case 'sj': return 'no';
        case 'um': return 'us';
        case 'bv': return 'no';
        default: return item;
      }
    },
    async getCountryByIP() {
      if (this.loading) return;
      this.loading = true;

      const { data } = await this.$store.dispatch('user/getCountryCodeByIP');

      this.loading = false;

      if (data.id) {
        this.$emit('update:country', data.id);
        this.flagCode = data.attributes.code;
      }
    }
  },
  async mounted() {
    if (this.autorun) {
      this.getCountryByIP();
    }

    if (!this.countries.length) {
      await this.$store.dispatch('user/getAllCountries');
    }
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  #countries {
    position: relative;

    .check_ip {
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      color: $grey-light14 !important;
      margin-right: 30px;
      position: absolute;
      left: 8px;
      top: 14px;

      img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
    }
    ::v-deep .v-select__slot {
      padding-left: 20px;
    }
    ::v-deep .v-list-item {
      color: $black !important;
      font-size: 14px !important;

      &.v-list-item--active {
        &::before { opacity: 0 !important; }

        &::after {
          content: url($checkbox-active-green);
          width: 20px;
          position: absolute;
          right: 25px;
          top: 13px;
        }
      }
      img {
        width: 18px;
        height: 18px;
        margin-right: 10px;
      }
    }

    ::v-deep input {
      max-width: 90%;
    }

     @media screen and (max-width: 960px) {
        ::v-deep input { max-width: unset; }
     }

    @media screen and (max-width: 760px) {
      ::v-deep input { max-width: calc(100% - 20px); }
    }
  }
</style>
