<template lang="pug">
  .complete
    .complete_signup(:class=" {'complete__item': complete} ")
      .complete_signup-language.d-flex.align-center.justify-end
        LanguageSwitching
      .complete_signup-form_block
        h2 {{ $t('hints.completeSignupForm') }}
        p(v-if="complete") {{ $t('emailConfirmation.description', { email }) }}
        v-form(ref="form", v-model="valid", @submit.prevent="")
          .complete_signup-firstName
            div {{ $t('formFields.firstName') }}
            v-text-field(
              v-model.trim="firstName",
              :rules="[rules.required, rules.name]",
              @input="capitalize($event, 'firstName')")
          .complete_signup-lastName
            div {{ $t('formFields.lastName') }}
            v-text-field(
              v-model.trim="lastName",
              :rules="[rules.required, rules.name]",
              @input="capitalize($event, 'lastName')")
          .complete_signup-country
            div {{ $t('hints.countrySelect') }}
            CountryAutocomplete(:country.sync="country", autorun, @updateCountry="updateCountry")
          // PolicyCheckboxes(v-if="registration_type !== 1 && !complete", :policy.sync="policy", :terms.sync="terms")
          .errors(v-if="errors.length")
            .errors__item(v-for="error in errors") #[strong {{ error.title }}]: #[span(v-html="error.detail")]
          v-btn.complete_signup-next(:disabled="!valid || loading", @click="submitForm", type="submit") {{ $t('actions.next') }}
      ForbiddenCountry(v-if="countrySupported")
</template>

<script>
import { mapState } from 'vuex';
import CountryAutocomplete from '@/components/CountryAutocomplete';
import ForbiddenCountry from '@/components/ForbiddenCountry';
// import PolicyCheckboxes from '@/components/PolicyCheckboxes';
import REGEXP from '@/regexp';
import CONSTANTS from '@/constants';
import LanguageSwitching from '@/components/LanguageSwitching';
import { analyticsEvents, sendAnalyticsEvent } from '@/analytics';

export default {
  props: {
    type: {
      type: String,
      default: 'form',
      validator(val) {
        return ['form', 'google', 'facebook'].indexOf(val) !== -1;
      }
    },
    complete: {
      type: Boolean,
      default: false
    }
  },
  components: {
    CountryAutocomplete,
    LanguageSwitching,
    ForbiddenCountry
    // PolicyCheckboxes
  },
  data() {
    return {
      valid: false,
      loading: false,
      errors: [],
      firstName: '',
      lastName: '',
      country: '',
      policy: false,
      terms: false,
      countrySupported: false
    };
  },
  computed: {
    ...mapState('user', [
      'acceptedLastVersions',
      'loggedIn',
      'first_name',
      'last_name',
      'registration_country_id',
      'email',
      'latestVersionTerms',
      'latestVersionPolicy',
      'registration_type',
      'countries'
    ]),
    rules() {
      return {
        required: v => !!v || this.$t('validation.required'),
        name: v => REGEXP.name.test(v) || this.$t('validation.nameRules', { min: 1, max: 160 })
      };
    },
    registrationTypeForGTM() {
      if (this.registration_type === 1) return 'form';
      if (this.registration_type === 2) return 'google';
      return 'facebook';
    }
  },
  methods: {
    capitalize(val, propName) {
      this[propName] = val.charAt(0).toUpperCase() + val.slice(1);
    },
    submitForm() {
      if (!this.$refs.form.validate() || this.loading) return;

      this.loading = true;

      const payload = {
        data: {
          type: 'user-profile-internal',
          attributes: {
            first_name: this.firstName,
            last_name: this.lastName,
            registration_country_id: Number(this.country),
            terms_version: this.latestVersionTerms?.id,
            privacy_version: this.latestVersionPolicy?.id
          }
        }
      };

      this.loading = true;

      this.$store.dispatch('user/updateProfile', payload)
        .then(() => {
          const serviceId = localStorage.getItem('service_id');
          let serviceIndex = '';

          sendAnalyticsEvent('registration_success');

          if (serviceId) {
            serviceIndex = CONSTANTS.SERVICES_MENU.find(s => s.id === +serviceId).gtm_index;
          }

          if (process.env.VUE_APP_GTM_ID && window.ga && window.ga.create) {
            window.dataLayer = window.dataLayer || [];

            window.dataLayer.push({
              event: 'autoEvent',
              eventCategory: 'redirect',
              eventAction: `${serviceIndex || 'id'}.Registration success`,
              eventLabel: this.registrationTypeForGTM
            });
          }

          const isCountryRestricted = this.countries.find(item => item.id === this.country).is_country_restricted;

          if (!isCountryRestricted) {
            if (!this.complete) {
              console.log(localStorage.getItem('referrer'));
              this.$store.dispatch('user/signinService', {
                serviceId: 3,
                redirectUrl: localStorage.getItem('referrer') || `${process.env.VUE_APP_KICKEX_BEGINNING}`
              })
                .catch(errors => { this.errors = errors; });
            } else {
              this.$emit('userInfo');
            }
          } else {
            this.countrySupported = true;
          }
        })
        .catch(errors => { this.errors = errors; })
        .finally(() => { this.loading = false; });
    },
    updateCountry(id) {
      this.country = id;
    }
  },
  mounted() {
    if (this.$route.name === 'complete-signup') {
      this.$store.dispatch('user/setImgName', 'completesignup');
    }
    if (this.first_name) {
      this.firstName = this.first_name;
    }

    if (this.last_name) {
      this.lastName = this.last_name;
    }

    if (this.registration_country_id) {
      this.country = String(this.registration_country_id);
    }

    analyticsEvents.twitterEventCode();
  }
};
</script>

<style lang="scss" scoped>
  @import '../assets/scss/variables';

  .complete_signup {
    max-width: 360px;
    width: 100%;
    margin: 0 auto;
    @media screen and (max-width: 530px) {
      max-width: 100%;
    }
    &-language{
      width: 100%;
      @media screen and (max-width: 530px) {
        border-bottom: 1px solid rgba(31, 33, 38, 0.1);
        padding:  0 20px 14px;
      }
    }
    &-form_block{
      @media screen and (max-width: 530px) {
        padding: 0 20px;
      }
    }
    &-next{
      height: 40px !important;
      background: #0071E3!important;
      border-radius: 5px;
      font-weight: 500;
      font-size: 14px!important;
      line-height: 16px;
      text-align: center;
    }
    &-firstName, &-lastName, &-country {
      >div{
        margin-bottom: 5px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: rgba(31, 33, 38, 0.6);
        text-align: left;
      }
    }
  }
  .complete__item{
    &-not__now{
      height: 40px !important;
      background: rgba(31, 33, 38, 0.05)!important;
      border-radius: 5px;
      font-weight: 500;
      font-size: 14px!important;
      line-height: 16px;
      text-align: center;
      color: #1F2126!important;
      margin-top: 10px;
    }
  }
  h2 {
    margin: 10vh 0 3vh;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 35px;
    text-align: center;
    color: #1F2126;
    @media screen and (max-width: 530px) {
      margin: 20px 0 10px;
      text-align: left;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
    }
  }
  p{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #1F2126;
    @media screen and (max-width: 530px) {
      font-size: 12px;
      line-height: 20px;
      text-align: left;
      margin-bottom: 20px;
    }
  }
  ::v-deep .v-input__slot {
    font-weight: 600;
  }

  ::v-deep .v-btn {
    width: 100%;
  }
  ::v-deep .v-input__append-inner {
    top: 0!important;
    right: -7px!important;
  }
  .double > div { max-width: 48%; }

  @media screen and (max-width: 760px) {

    .double {
      flex-wrap: wrap;

      & > div {
        width: 100%;
        max-width: unset;
      }
    }
  }

  @media screen and (max-width: 600px) {
    h2 { margin-left: 0; }
  }

  ::v-deep .v-text-field .v-label{
    opacity: 0;
  }
  ::v-deep .v-text-field .v-input__slot{
    height: 45px;
    padding-top: 5px;
  }
  ::v-deep .password .v-input__append-inner {
    margin-top: 6px !important;
  }
  ::v-deep .v-text-field input {
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    color: #1F2126;
  }
</style>
