// eslint-disable-next-line import/prefer-default-export
export function sendAnalyticsEvent(action) {
  if (
    !process.env.VUE_APP_YM_ID
    || typeof window === 'undefined'
    || typeof window.ym === 'undefined'
  ) return;

  console.log('sendAnalyticsEvent', action);

  window.ym(process.env.VUE_APP_YM_ID, 'reachGoal', action);
}

export const analyticsEvents = {
  twitterEventCode() {
    console.log('sendAnalyticsEvent', 'twitterEventCode');

    window.twq('event', 'tw-omnzc-omrt5', {
      value: null, // use this to pass the value of the conversion (e.g. 5.00)
      currency: null, // use this to pass the currency of the conversion with an ISO 4217 code (e.g. ‘USD’)
      status: null, // use this to pass the status of a sign up or subscription, (e.g. 'started' or 'completed')
      conversion_id: null, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')
      email_address: null, // use this to pass a user’s email address
      phone_number: null // phone number in E164 standard
    });
  }
};
