<template lang="pug">
  .forbidden__country
    img(src="../assets/images/map.svg")
    h2(v-html="$t('actions.supported')")
    p {{ $t('actions.restrictedCountryDescription') }}
    router-link(:to="{ name: 'Settings' }") {{ $t('actions.continue') }}
</template>

<script>
export default {
  name: 'forbiddenCountry'
};
</script>

<style lang="scss" scoped>
  .forbidden__country{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: #feebed;
    img{
      margin-top: 10vh;
      height: 45vh;
      @media screen and (max-width: 750px) {
        width: 90%;
      }
    }
    h2 {
      font-weight: 500;
      font-size: 40px;
      line-height: 50px;
      text-align: center;
      color: #F83E4B;
      margin: 0 auto;
      @media screen and (max-width: 580px) {
        font-size: 20px;
        line-height: 26px;
      }
    }
    p{
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #000000;
      margin: 3vh auto 3vh;
      width: 380px;
      @media screen and (max-width: 580px) {
        font-size: 12px;
        line-height: 20px;
        width: 90%;
      }
    }
    a{
      background: #1F2126;
      border-radius: 5px;
      padding: 12px 30px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #FFFFFF;
      text-decoration: none;
    }
  }
</style>
